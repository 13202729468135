@import "../../shared";

.progression-player {
    display: none;
    opacity: 0;
    transition: opacity 0.5s;
    
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: none;
    justify-content: center;
    align-items: center;
    background-color: #00000077;

    &.visible {
        display: flex;
        opacity: 1;
    }

    .content {
        position: relative;
        width: 70%;
        aspect-ratio: 1920 / 1080;
        padding: 30px;
        box-sizing: border-box;
        transition: color 0.3s;
        &.error {
            border-radius: 30px;
            background-color: #FFFFFF;
            color: #000000;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .close-button {
            font-size: 30px;
            position: absolute;
            top: 30px;
            right: 30px;
            z-index: 1;
            transition: color 0.3s;
        }
        .background {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 30px;
            z-index: -1;
            &.bg-color {
                transition: background-color 0.3s;
            }
            &.bg-image {
                background-size: cover;
                opacity: .15;
                transition: background-image 0.3s linear;
            }
        }
        .foreground {
            height: 100%;
            overflow: hidden;
            .slideshow {
                height: 80%;
                display: grid;
                grid-template-columns: 33.3% 33.3% 33.3%;
                transition: transform 0.3s;
                .slide {
                    transition: scale 0.3s;
                }
                .slide.chord {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .chord-name {
                        font-weight: bold;
                        font-size: 40px;
                        text-align: center;
                    }
                    &:not(.current) {
                        scale: 0.65;
                    }
                }
                .slide.break {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .vline {
                        width: 0px;
                        height: 50%;
                        border-left-width: 3px;
                        border-left-style: solid;
                    }
                }
                .slide.repeat {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    .repeat-icon {
                        font-size: 80px;
                    }
                    p {
                        font-weight: bold;
                        font-size: 40px;
                        text-align: center;
                    }
                }
            }
            .controls {
                height: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 30px;
                .scroll-button {
                    transition: color 0.3s;
                    font-size: 40px;
                    &.disabled {
                        opacity: .30;
                    }
                }
            }
        }
    }
}