@import "../../shared";

.circle-selector {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 110px;
	& > button {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
	}
	.arrow {
		font-size: 20px;
		color: $fg-color;
		transition: color .2s;
		&:hover {
			color: $accent-color-fg;
		}
	}
	.circle-window {
		overflow: hidden;
		border-radius: 10em;
		background-color: adjust-color($bg-color, $lightness: +10%);
		width: 50px;
		height: 50px;
		position: relative;
		div {
			font-weight: bold;
			font-size: 17px;
			font-family: $font-sans-serif;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: center;
			vertical-align: middle;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&.disabled {
		.arrow {
			color: $disabled-color;
		}
	}
}
