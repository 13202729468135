@import "../../shared";

input.custom, textarea.custom {
    font-family: $font-sans-serif;
    border: 2px $fg-color solid;
	color: $fg-color;
	background-color: adjust-color($bg-color, $lightness: +5%);
    padding: 7px;
    font-size: 13px;
    border-radius: 4px;
    transition: border-color .3s;
    caret-color: $fg-color;
    font-weight: bold;
    &:focus {
        outline: none;
        border-color: $accent-color-fg;
    }
    &.error {
        border-color: $error-color;
    }
}

textarea.custom {
    resize: vertical;
}
