@import "../../shared";

#voicings-main-content {
    padding-top: 5vh;
    min-height: 90vh;

    #quick-input-error {
        font-size: 20px;
        min-height: 25px;
        margin-top: 25px;
        margin-bottom: 25px;
        min-height: 1px;
    }
    #centered-button {
        padding: 50px;
        text-align: center;
    }
}