@import "../../shared";

.loader-shade {
	width: 100%;
	height: 100%;
	background-color: #000000CC;
    z-index: 10;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: inherit;
}

.loader-icon {
	color: $accent-color-fg;
}
