@import "../../shared";

#landing-main-content {
    margin-top: 10vh;
}

#title {
    font-size: 15vh;
    margin-bottom: 3vh;
}

#subtitle {
    font-size: 5vh;
    margin-bottom: 4vh;
}