@import "../../shared";

.popup-container {
	position: relative;
	display: inline-block;
	.popup-content {
		background-color: adjust-color($bg-color, $lightness: +15%);
		padding: 10px;
		border-radius: 10px;
		text-align: center;
		.popup-arrow {
			background-color: $fg-color;
		}
	}
}
