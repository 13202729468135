@import "../../shared";

#chord-library-main-content {
    padding-top: 5vh;
    min-height: 90vh;
}

#chord-library-main-content.error {
    button {
        margin-top: 30px;
    }
    p {
        font-size: 30px;
    }
}

#about-area {
    text-align: center;
    padding: 0px 30%;
    h2 {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
}

#tags-area {
    margin-top: 6vh;
    margin-bottom: 6vh;
    text-align: center;
    h2 {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    #tags-carousel {
        padding: 10px 0px;
        height: fit-content;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        a {
            margin-right: 15px;
        }
    }
    button {
        margin-top: 20px;
    }
}

.dropdown-button {
    display: block;
    margin: 20px auto;
    color: $accent-color-fg;
    font-weight: bold !important;
    svg {
        margin-left: 10px;
    }
}