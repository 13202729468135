@import "../../shared";

#library-faq {
    border: 1px solid $accent-color-fg;
    border-radius: 20px;
    padding: 30px;
    margin: 0px 50px;
    & > h2 {
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
    & > p {
        margin-top: 30px;
    }
    & a {
        color: $accent-color-fg;
    }
}