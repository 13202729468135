@import "../../shared";

#my-projects-main-content {
    padding-top: 15vh;
}

#greeting {
    font-size: 5vw;
    margin-bottom: 15px;
}

#greeting-subtitle {
    font-size: 35px;
    margin-bottom: 5vh;
}

#project-list {
    margin-top: 50px;
    .item {
        background-color: adjust-color($bg-color, $lightness: +10%);
        margin: 20px 0;
        padding: 25px;
        border-radius: 7px;
        border: #555555 1px solid;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        h3 {
            font-size: 25px;
            margin-bottom: 10px;
        }

        .user-info-display {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            p {
                display: inline;
                margin-left: 10px;
                font-size: 17px;
            }
        }
        &.invite {
            border-color: $accent-color;
            @include glow-shadow();
            h3 {
                color: $accent-color-fg;
            }
            .prefix {
                color: #FFFFFF;
                font-weight: normal;
            }
        }
    }
}