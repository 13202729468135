@import "../../shared";

.profile-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.song-profile-tag {
    display: inline-block;
    text-decoration: none;
    color: #FFFFFF;
    font-weight: bold;
    div {
        white-space: nowrap;
        background-image: linear-gradient(to bottom right, $accent-color-fg, $accent-color);
        background-color: #AAAAAA;
        background-blend-mode: multiply;
        border-radius: 3px;
        padding: 8px 14px;
        font-size: 15px;
        transition: background-color .3s;
        &:hover {
            border-color: $accent-color;
            background-color: #FFFFFF;
        }
    }
}

.song-profile {
    padding: 30px;
    border: $accent-color 1px solid;
    border-radius: 30px;
    
    .name-text {
        font-size: 40px;
        color: $accent-color-fg;
        margin-bottom: 10px;
    }
    .artist-text {
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    .tags {
        white-space: pre-wrap;
        a {
            margin-right: 15px;
            margin-bottom: 15px;
        }
        margin-bottom: 40px;
    }
    .unexpanded-chords {
        font-size: 20px;
        margin-bottom: 20px;
        overflow-wrap: break-word;
        .unexpanded-section-name {
            font-weight: bold;
            color: $accent-color-fg;
            text-transform: uppercase;
        }
        .serialized-chord.chord span {
            margin-right: 0;
        }
    }

    .commentary {
        font-size: 20px;
        margin-bottom: 15px;
        white-space: pre-wrap;
    }
    .similar-to, .outlinks {
        font-size: 17px;
        margin-bottom: 30px;
        a {
            text-decoration: underline $accent-color-fg;
            color: #FFFFFF;
        }
    }
    .section-name-text {
        text-transform: uppercase;
        font-size: 20px;
        color: $accent-color-fg;
        margin-bottom: 15px;
    }
    .song-section {
        padding: 15px 0px;
        .info-text {
            margin-bottom: 15px;
        }
        .section-settings {
            min-height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;
            gap: 20px;
            .dropdown {
                width: 20%;
            }
        }
    }
    
    .outlinks {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        img {
            height: 30px;
        }
    }
    &.expanded {
        margin: 30px 0;
    }
    &:not(.expanded) {
        background-image: linear-gradient(to bottom right, darken($accent-color, 50%), darken($accent-color, 40%));
        background-color: #555555;
        background-blend-mode: multiply;
        border: transparentize($accent-color, .2) 1px solid;
        border-radius: 30px;
        transition: border-color .3s, background-color .3s;
        cursor: pointer;

        &:hover {
            border-color: $accent-color;
            background-color: #AAAAAA;
        }

        &:active {
            background-color: #FFFFFF;
            transition: background-color .1s;
        }
        .name-text {
            font-size: 30px;
        }
        .artist-text {
            opacity: 70%;
            margin-bottom: 20px;
        }
        .tags {
            margin-bottom: 10px;
            .song-profile-tag div {
                font-size: 13px;
                padding: 6px 12px;
            }
        }
    }
}
