@import "../../shared";

#chord-chart-main-content {
    padding-top: 5vh;
    min-height: 90vh;
}

.subtitle {
    margin-bottom: 3vh;
}

#instrument-select-panel {
    margin: 0 auto;
    max-width: 200px;
}



#main-table {
    margin-top: 5vh;
    padding: 0 5vw;
    width: 100%;
    border-collapse: collapse;
    td:not(.degree-labels) {
        // border: 1px solid #FFFFFF;
        padding: 15px 0;
        p {
            font-weight: lighter;
            font-size: 20px;
            text-align: center;
        }
        .chord-diagram {
            margin: 0 auto;
        }
    }
    .degree-labels {
        border-right: 1px solid #FFFFFF;
        p {
            font-size: 30px;
            font-weight: bold;
            color: $accent-color;
            text-align: center;
        }
    }
}

#chord-chart-main-content.print-mode {
    background-color: #FFFFFF;
    color: #000000;
    margin: 0;
    padding: 0;
    #instrument-select-panel, .options-panel {
        display: none;
    }
    #main-table {
        margin: 0;
        padding: 0;
        .degree-labels {
            border-right-color: #000000;
        }
    }
}