$bg-color: #050505;
$fg-color: #FFFFFF;
$accent-color: #624aff;
$accent-color-fg: #8674ff;
$error-color: #ff4a4a;
$disabled-color: #999999;

$font-sans-serif: "Mulish", "Roboto", "Arial", sans-serif;
$font-serif: "Lora", "Times", serif;

$MOBILE_WIDTH: 1000px;

@mixin responsive() {
    @media ( max-width: + $MOBILE_WIDTH ) {
        @content;
    }
}

@mixin glow-shadow() {
    box-shadow: 0px 0px 20px 10px transparentize($accent-color, .8);
}

@mixin placeholder {
    ::-webkit-input-placeholder {@content};
    :-moz-placeholder           {@content};
    ::-moz-placeholder          {@content};
    :-ms-input-placeholder      {@content};
}