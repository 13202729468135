@import "../../shared";

#chord-tab-main-content {
    padding-top: 5vh;
    #quick-input-error {
        font-size: 20px;
        min-height: 25px;
        margin-top: 25px;
        margin-bottom: 25px;
        min-height: 1px;
    }
    .options-panel {
        margin-bottom: 50px;
    }
}