@import "./Modal";

.new-project-modal {
	text-align: left;
	& > h1 {
		text-align: center;
		margin-bottom: 15px;
	}
	& h2 {
		color: $accent-color-fg;
		margin-top: 20px;
		margin-bottom: 15px;
	}
	& > #time-signature-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		& > #time-sig-display {
			text-align: center;
			font-size: 50px;
			font-family: $font-serif;
			margin-right: 25%;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	& input {
		width: 40% !important;
	}
	@include responsive(){
		& input {
			width: 95% !important;
		}
	}
}
