@import "../../shared";

#tabs-faq {
    border: 1px solid $accent-color-fg;
    border-radius: 20px;
    padding: 30px;
    & > h2 {
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
    & > p {
        margin-top: 30px;
    }
    code {
        font-size: 18px;
    }
}

#tabs-faq, #tabs-faq-button {
    margin-bottom: 50px;
}