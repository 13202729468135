@import "../../shared";

#auth-main-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh;
}

#centered-pad {
    width: 40vw;
    text-align: center;

    @include responsive(){
        width: 80vw;
    }

    & > h1 {
        padding-bottom: 30px;
    }
}