
@import "../../shared";

#chord-overview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    div.item {
        display: block;
        margin: 0 20px;
        padding: 15px 0;
        p {
            font-weight: lighter;
            font-size: 20px;
            text-align: center;
        }
        .chord-diagram {
            margin: 0 auto;
        }
    }
    div.break {
        display: block;
        flex-basis: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        .break-line {
            width: 70px;
            border-top: 2px #FFFFFF solid;
        }
    }
    div.repeat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 0 20px;
        padding: 15px 0;
        .repeat-icon {
            margin-top: 20px; // To fill the space where the chord name would go
            font-size: 30px;
        }
        p {
            font-weight: lighter;
            font-size: 20px;
            text-align: center;
        }
    }
}
