@import "../../shared";

.landing-page-link {
    display: block;
    text-decoration: none;
    color: #FFFFFF;
    
    & > div {
        margin: 30px 0px;
        padding: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        background-image: linear-gradient(to bottom right, darken($accent-color, 50%), darken($accent-color, 40%));
        background-color: #555555;
        background-blend-mode: multiply;
        border: transparentize($accent-color, .2) 1px solid;
        border-radius: 30px;
        color: adjust-color($accent-color, $lightness: +15%);
        transition: border-color .3s, background-color .3s;
        &:hover {
            border-color: $accent-color;
            background-color: #AAAAAA;
        }
    
        &:active {
            background-color: #FFFFFF;
            transition: background-color .1s;
        }
    }
    
    h2 {
        font-family: "Secular One", "Mulish", sans-serif;
        font-size: 3vh;
        font-weight: bold;
    }
    p {
        font-size: 2.2vh;
    }
    .icon {
        font-size: 30px;
        margin-right: 30px;
    }
}