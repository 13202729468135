@import "../../shared";

button.custom {
	font-family: "Secular One", "Mulish", sans-serif;
	background-image: linear-gradient(to bottom right, darken($accent-color, 50%), darken($accent-color, 40%));
	background-color: #555555;
	background-blend-mode: multiply;
	border: transparentize($accent-color, .2) 3px solid;
	color: adjust-color($accent-color, $lightness: +15%);
    font-weight: bold;
	border-radius: 10em;
	font-size: 15px;
	padding: 7px 9px;
	transition: border-color .3s, background-color .3s;
	text-transform: uppercase;

	&.jumbo {
		font-size: 20px;
		padding: 12px 17px;
	}

	&:hover {
		border-color: $accent-color;
		background-color: #AAAAAA;
	}

	&:active {
		background-color: #FFFFFF;
		transition: background-color .1s;
	}

	&.disabled {
		color: adjust-color($accent-color, $saturation: -100%);
		border-color: adjust-color($accent-color, $saturation: -100%);
		background: none;
	}
}

button.custom.primary {
    background-image: linear-gradient(to bottom right, $accent-color, adjust-color($accent-color, $lightness: -10%));
    background-blend-mode: multiply;
	background-color: #FFFFFF;
	color: #FFFFFF;
    border: none;
    font-size: 20px;
    padding: 12px 17px;
    transition: background-color .3s;
	@include glow-shadow();
	
	&.jumbo {
		font-size: 30px;
		padding: 17px 22px;
	}
	
	&:hover {
		background-color: #BBBBBB;
		transition: background-color .5s;
	}
	
	&:active {
		background-color: #999999;
		transition: background-color .1s;
	}

	&.disabled {
		background-color: #777777;
		color: #777777;
		box-shadow: none;
	}
}

button.custom.subtle {
	background-image: none;
	background-color: unset;
	border: none;
	&:hover {
		background-color: transparentize($accent-color, 0.5);
	}
	&:active {
		background-color: transparentize($accent-color, 0.3);
	}
}
