@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import "./shared";

body {
	background-color: $bg-color;
	color: $fg-color;
	font-family: $font-sans-serif;
	overflow-x: hidden;
}

#root {
	overflow-x: hidden;
}

button:focus {
    outline: none;
}
button.nostyle {
	background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
}

a.nostyle {
	text-decoration: none;
	color: inherit;
}

h1, h2, h3 {
	font-weight: bold;
	margin: 0;
}
p {
	margin: 0;
}

.rails {
	margin-left: 25vw;
	margin-right: 25vw;
	@include responsive(){
		margin-left: 10vw;
		margin-right: 10vw;
	}
}
.wide-rails {
	margin-left: 15vw;
	margin-right: 15vw;
	@include responsive(){
		margin-left: 5vw;
		margin-right: 5vw;
	}
}

.accent {
	color: $accent-color-fg;
}

.error {
	color: #ff4929;
}

.title {
	font-size: 13vh;
	font-weight: bolder;
	@include responsive(){
		font-size: 13vh;
	}
}

.subtitle {
	font-size: 9vh;
	font-weight: bold;
}

@include placeholder {
	color: adjust-color($fg-color, $lightness: -30%);
}

.profile-pic {
	img {
		border-radius: 50%;
	}
}

.gradient-text {
	color: $accent-color-fg;
    background: linear-gradient(to bottom right, $accent-color-fg, $accent-color);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.centered {
	text-align: center;
}


.options-panel {
    margin: 0 auto;
    margin-top: 3vh;
    padding: 20px;
    max-width: 400px;
    border: 1px $fg-color solid;
    border-radius: 5px;
    & > div {
        &:not(:first-child) {
            margin-top: 15px;
        }
        & > p {
            font-weight: bold;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .dropdown {
            min-width: 50%;
        }
    }
}

.shadow {
	box-shadow: 0px 0px 50px rgba($accent-color-fg, .7); 
}

.bullet-spacer {
	padding: 0 10px;
	color: $accent-color-fg;
}

$greyed-out-chord: #999999;
.serialized-chord {
	&.break {
		color: $accent-color-fg;
	}
	&.repeat {
		color: $accent-color-fg;
	}
	&.chord {
		.duration {
			color: $greyed-out-chord;
			margin-right: 5px;
		}
		.root {
		}
		.quality {
			margin-right: 3px;
		}
		.slash {
			color: $greyed-out-chord;
			margin-right: 3px;
		}
		.bass {
			color: $greyed-out-chord;
		}
	}
}
.chord-space {
	margin-right: 10px;
}
