@import "../../shared";

.dropdown {
    position: relative;
    .display {
        font-family: $font-sans-serif;
        border: 2px $fg-color solid;
        color: $fg-color;
        background-color: adjust-color($bg-color, $lightness: +5%);
        padding: 7px;
        font-size: 13px;
        border-radius: 4px;
        transition: border-color .3s;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &.active {
            outline: none;
            border-color: $accent-color-fg;
            transition: border-color .3s;
        }
    }
    .options {
        margin-top: 3px;
        visibility: hidden;
        position: absolute;
        opacity: 0;
        min-width: 100%;
        transition: visibility .3s, opacity .3s;
        background-color: adjust-color($bg-color, $lightness: +5%);
        z-index: 1;
        border: 2px $accent-color solid;
        border-radius: 4px;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;
        &.active {
            opacity: 1;
            visibility: visible;
            transition: visibility .3s, opacity .3s;
        }
        .item {
            padding: 10px;
            border-bottom: 2px $accent-color solid;
            transition: background-color .3s;
            svg {
                margin-right: 10px;
                color: $accent-color;
            }
            &.last {
                border-bottom: none;
            }
            &:hover {
                background-color: $accent-color;
                transition: background-color .3s;
            }
        }
    }

    &.disabled {
        .display {
            border-color: $disabled-color;
            color: $disabled-color;
        }
    }
}
