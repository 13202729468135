@import "../../shared";

.modal {
	position: relative;
	border-radius: 15px;
	background-color: $bg-color;
	border: $accent-color 3px solid;
	@include glow-shadow();
	padding: 30px;
	max-width: 40vw;
	@include responsive(){
		max-width: 70vw;
	}
	.close-button {
		font-size: 35px;
		color: $fg-color;
		position: absolute;
		right: 15px;
		top: 15px;
		transition: color .2s;
		&:hover {
			color: $accent-color;
		}
	}
}

.modal-shade {
	width: 100%;
	height: 100%;
	background-color: #000000DD;
	position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.error-modal {
	text-align: center;
	background: $bg-color;
	border-radius: 15px;
	border: $accent-color 1px solid;
	@include glow-shadow();
	padding: 30px;
	max-width: 40vw;
	& > .error-title {
		font-size: 3vw;
	}
	& > .error-description {
		font-size: 25px;
	}
	& > .error-code {
		color: #888888;
	}

	@include responsive(){
		max-width: 70vw;
	}
}
